import type { ButtonProps } from "@chakra-ui/react";
import {
  type CardProps,
  Card as ChakraCard,
  Button,
  HStack,
  Text,
  Link,
  Divider,
} from "@chakra-ui/react";
import { faImageSlash } from "@fortawesome/pro-regular-svg-icons";

import {
  IconBadge,
  StordEmblemAndLogo,
  useIsDesktop,
} from "@stordco/fe-components";
import { ProductImage } from "../../components/ProductImage";

export const getShipmentName = (carrierName: string) =>
  /fedex/i.test(carrierName)
    ? "FedEx"
    : /ups\b/i.test(carrierName)
    ? "UPS"
    : /dhl/i.test(carrierName)
    ? "DHL"
    : /usps/i.test(carrierName)
    ? "USPS"
    : /lso/i.test(carrierName)
    ? "LSO"
    : /lasership/i.test(carrierName)
    ? "LaserShip"
    : null;

export const Card = (props: CardProps) => (
  <ChakraCard {...props} variant="outline" />
);

export const CXPProductImage = ({
  item,
}: {
  item: { image_urls: string[]; product_name: string };
}) => (
  <ProductImage
    url={item.image_urls?.[0]}
    size="48px"
    alt={item.product_name}
    fallback={
      <IconBadge
        variant="square"
        icon={faImageSlash}
        size="lg"
        bgColor="gray.50"
        iconStyles={{ color: "gray.300" }}
        boxSize={12}
      />
    }
  />
);

export const ContinueShoppingButton = ({
  url,
  ...props
}: ButtonProps & { url: string }) => (
  <Button
    as="a"
    target="_blank"
    variant="outline"
    colorScheme="gray"
    href={url}
    {...props}
  >
    Continue shopping
  </Button>
);

export const ContactUsButton = ({
  url,
  ...props
}: ButtonProps & { url: string }) => (
  <Button
    as="a"
    target="_blank"
    variant="outline"
    colorScheme="gray"
    href={url}
    {...props}
  >
    Contact us
  </Button>
);

export const PoweredByStordFooter = ({
  divider = true,
  alignment = "flex-end",
  hidePrivacyPolicy = false,
}: {
  divider?: boolean;
  alignment?: "flex-end" | "center";
  hidePrivacyPolicy?: boolean;
}) => {
  const isDesktop = useIsDesktop();

  return (
    <>
      {divider ? <Divider mt={isDesktop ? 6 : 10} /> : null}
      <HStack py={3} justifyContent={alignment} gap={2} w="full">
        <Link
          display="flex"
          textStyle="caption"
          href="https://www.stord.com/"
          target="_blank"
          color="gray.700"
        >
          Powered by&nbsp;
          <StordEmblemAndLogo widthInPx={56} />
        </Link>
        {!hidePrivacyPolicy ? (
          <>
            <Text textStyle="caption"> | </Text>
            <Link
              textStyle="caption"
              href="https://www.stord.com/legal/privacy-policy"
              target="_blank"
              color="gray.700"
            >
              Privacy Policy
            </Link>{" "}
          </>
        ) : null}
      </HStack>
    </>
  );
};
