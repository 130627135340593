import "@total-typescript/ts-reset";
import { Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ConfigProvider,
  DatadogProvider,
  PageLoader,
  SEO,
  StordChakraProvider,
} from "@stordco/fe-components";

import { ApiProvider } from "./ApiProvider";
import App from "./App";
import "tailwindcss/tailwind.css";
import { ErrorBoundaryReloadOnDynamicImport } from "./ErrorPages";
import { LDProvider } from "launchdarkly-react-client-sdk";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <StordChakraProvider>
      <ErrorBoundaryReloadOnDynamicImport>
        <Suspense fallback={<PageLoader />}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools
                position="bottom"
                buttonPosition="bottom-right"
              />
              <ConfigProvider>
                {(config) => (
                  <DatadogProvider
                    version={__APP_VERSION__}
                    applicationId={config.DATADOG_APPLICATION_ID}
                    clientToken={config.DATADOG_CLIENT_TOKEN}
                    service={config.DATADOG_SERVICE_NAME}
                    env={config.DATADOG_ENV}
                  >
                    <LDProvider
                      clientSideID={config.LD_CLIENT_ID}
                      reactOptions={{
                        sendEventsOnFlagRead: true,
                        useCamelCaseFlagKeys: true,
                      }}
                    >
                      <ApiProvider baseUrl={config.API_URL}>
                        <SEO titleTemplate="%s | Stord" defaultTitle="Stord" />
                        <App />
                      </ApiProvider>
                    </LDProvider>
                  </DatadogProvider>
                )}
              </ConfigProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </Suspense>
      </ErrorBoundaryReloadOnDynamicImport>
    </StordChakraProvider>
  </StrictMode>,
);
