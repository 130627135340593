import {
  DatadogErrorBoundary,
  type DatadogErrorBoundaryProps,
  PageLoader,
  FaIcon,
} from "@stordco/fe-components";
import { Text, Flex, Box, Badge, Button } from "@chakra-ui/react";
import {
  faArrowLeft,
  faArrowsRotate,
} from "@fortawesome/pro-regular-svg-icons";
import { type ReactNode } from "react";

export const ErrorPage = ({
  badgeText,
  title,
  description,
  secondaryButton,
  primaryButton,
}: {
  badgeText?: string;
  title: string;
  description: string;
  secondaryButton?: ReactNode;
  primaryButton?: ReactNode;
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100vw"
    direction="column"
    gap={4}
    px={4}
  >
    {badgeText ? (
      <Badge size="xl" bgColor="gray.900" color="white">
        {badgeText}
      </Badge>
    ) : null}
    <Text
      as="h1"
      textStyle="title"
      fontSize="30px"
      lineHeight="40px"
      fontWeight={700}
      textAlign="center"
    >
      {title}
    </Text>
    <Box maxW="480px" textAlign="center">
      <Text>{description}</Text>
    </Box>
    <Flex gap={2} mt={2}>
      {secondaryButton}
      {primaryButton}
    </Flex>
  </Flex>
);

interface ErrorBoundaryProps extends DatadogErrorBoundaryProps {
  children: ReactNode;
}
export const ErrorBoundaryReloadOnDynamicImport = ({
  children,
  ...props
}: ErrorBoundaryProps) => {
  return (
    <DatadogErrorBoundary
      fallback={({ error }) => {
        if (
          error.message?.includes(
            "Failed to fetch dynamically imported module:",
          )
        ) {
          window.location.reload();
          return <PageLoader />;
        }
        return (
          <ErrorPage
            title="Hmmm..."
            description="We're having trouble loading your information. Try refreshing or going back to the previous page."
            secondaryButton={
              <Button
                leftIcon={<FaIcon icon={faArrowLeft} />}
                onClick={() => window.history.back()}
                variant="outline"
                colorScheme="gray"
              >
                Go back
              </Button>
            }
            primaryButton={
              <Button
                leftIcon={<FaIcon icon={faArrowsRotate} />}
                onClick={() => window.location.reload()}
                variant="outline"
                colorScheme="gray"
              >
                Refresh
              </Button>
            }
          />
        );
      }}
      {...props}
    >
      {children}
    </DatadogErrorBoundary>
  );
};

export const NotFoundErrorPage = () => (
  <ErrorPage
    badgeText="404 not found"
    title="Did you take a wrong turn?"
    description="You might be lost; this page doesn't seem to exist."
    primaryButton={
      <Button
        leftIcon={<FaIcon icon={faArrowLeft} />}
        onClick={() => window.history.back()}
        variant="outline"
        colorScheme="gray"
      >
        Go back
      </Button>
    }
  />
);
