import type { RefObject } from "react";
import { useEffect, useState } from "react";

export function useIsImageLoaded(imageRef: RefObject<HTMLImageElement>) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!imageRef.current) {
      return;
    }

    let unmounted = false;

    const handleError = () => {
      if (unmounted) {
        return;
      }

      setError(true);
    };

    const imageEl = imageRef.current;

    imageEl.addEventListener("error", handleError);

    return () => {
      unmounted = true;

      imageEl.removeEventListener("error", handleError);
    };
  }, [imageRef]);

  useEffect(() => {
    if (!imageRef.current) {
      return;
    }

    let unmounted = false;

    const imageEl = imageRef.current;

    if (imageEl.complete) {
      setLoaded(true);

      return;
    }

    const handleLoad = () => {
      if (unmounted) {
        return;
      }

      setLoaded(true);
    };

    imageEl.addEventListener("load", handleLoad);

    return () => {
      unmounted = true;
      imageEl.removeEventListener("load", handleLoad);
    };
  }, [imageRef]);

  return { loaded, error };
}
