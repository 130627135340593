import type { SkeletonProps } from "@chakra-ui/react";
import { Image as ChakraImage, Skeleton } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { useRef } from "react";

import { StordLogoIconBadge, useIsImageLoaded } from "@stordco/fe-components";

export const ProductImage = ({
  url,
  size = "32px",
  styles,
  alt,
  fallback,
}: {
  url: string | null | undefined;
  size?: `${number}px`;
  styles?: SkeletonProps;
  alt?: string;
  fallback?: ReactNode;
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const { loaded, error } = useIsImageLoaded(imageRef);

  if (!url || error) {
    return fallback ? (
      fallback
    ) : (
      <StordLogoIconBadge boxSize={size} colorScheme="gray" />
    );
  }

  let imageSrc = url;

  if (url.includes("cdn.shopify.com")) {
    imageSrc = addSizeParamsToShopifyUrl(url, parseInt(size) * 2);
  }

  return (
    <Skeleton isLoaded={loaded} borderRadius="base" {...styles}>
      <ChakraImage
        ref={imageRef}
        src={imageSrc}
        alt={alt}
        borderRadius="base"
        minWidth={size}
        width={size}
        loading="lazy"
        aspectRatio={1}
        objectFit="contain"
      />
    </Skeleton>
  );
};

const addSizeParamsToShopifyUrl = (urlParam: string, boxSize: number) => {
  const url = new URL(urlParam);

  const pathParts = url.pathname.split("/");
  const lastPart = pathParts.pop();

  if (!lastPart) return url.toString(); // Shouldn't happen, but just in case

  const extensionIndex = lastPart.lastIndexOf(".");

  if (extensionIndex === -1) return url.toString(); // No file extension found

  const resizedLastPart = `${lastPart.substring(
    0,
    extensionIndex,
  )}_${boxSize}x${boxSize}${lastPart.substring(extensionIndex)}`;

  // Rebuild the pathname with the resized part
  pathParts.push(resizedLastPart);
  url.pathname = pathParts.join("/");

  return url.toString();
};
