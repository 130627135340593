import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { StordEmblemAndLogo } from "@stordco/fe-components";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PoweredByStordFooter } from "./common";
import { useAuthenticate, useGlobalOrderLookup } from "./queries";
import type { ViewOrderDetailsFields } from "./types";

export default function OrderSearch() {
  const { handleSubmit, control } = useForm<ViewOrderDetailsFields>({
    defaultValues: { email: "", order_number: "" },
  });

  const { mutateAsync: globalOrderLookup, isPending: isPendingLookup } =
    useGlobalOrderLookup();
  const { mutateAsync: authenticate, isPending: isPendingAuthenticate } =
    useAuthenticate();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async ({ email, order_number }: ViewOrderDetailsFields) => {
    try {
      const { order_id } = await globalOrderLookup({ email, order_number });

      await authenticate({ email, order_number, order_id });

      navigate(`/orders/${order_id}`);
    } catch (error) {
      toast({
        status: "error",
        title: "Invalid credentials",
        description:
          "There was an error authenticating your order. Please check your email and order number, and try again.",
      });
    }
  };

  return (
    <Box
      backgroundColor="gray.50"
      backgroundImage="url('cloud_1_lightmode.png'),
      url('cloud_2_lightmode.png'), url('cloud_3_lightmode.png')"
      backgroundPosition="calc(50% + 560px) 29rem, calc(50% - 640px) 24rem, calc(50% -
      280px) 4.5rem"
      backgroundRepeat="no-repeat"
      backgroundSize=" 598px 325px,457px 332px, 541px 472px"
      minHeight="100vh"
    >
      <Container maxW="6xl" pt={{ base: 8, md: 16 }} pb={{ base: 4, md: 10 }}>
        <VStack gap={8}>
          <Box>
            <StordEmblemAndLogo heightInPx={40} />
          </Box>
          <Box
            padding={{ base: 6, lg: 12 }}
            borderRadius={8}
            shadow="xl"
            background={"white"}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              id="view-order-details-form"
              noValidate
            >
              <VStack gap={2} alignItems="center">
                <Heading
                  as="h1"
                  fontSize={{ base: "3xl", lg: "5xl" }}
                  color={"black"}
                >
                  Find your order
                </Heading>
                <Text fontSize={{ base: "md", lg: "lg" }}>
                  Please enter your email and order number.
                </Text>
              </VStack>

              <VStack pt={4} gap={8}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    validate: (value) =>
                      /^[^@]+@[^@]+$/.test(value) ||
                      "This field must be an email address.",
                  }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={Boolean(fieldState.error)}
                    >
                      <FormLabel mb={2} fontSize={{ base: "18px", lg: "22px" }}>
                        Email
                      </FormLabel>

                      <Input
                        {...field}
                        autoCorrect="off"
                        placeholder="eg: name@domain.com"
                        px={{ base: 6, lg: 9 }}
                        py={{ base: 4, lg: 5 }}
                        borderRadius={{ base: "md", lg: "lg" }}
                        borderColor={"black"}
                        fontSize={{ base: "18px", lg: "22px" }}
                        height={"inherit"}
                      />
                      <FormErrorMessage fontSize={{ base: "sm", lg: "md" }}>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="order_number"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={Boolean(fieldState.error)}
                    >
                      <FormLabel mb={2} fontSize={{ base: "18px", lg: "22px" }}>
                        Order number
                      </FormLabel>

                      <Input
                        {...field}
                        autoCorrect="off"
                        placeholder="eg: ORDER123456"
                        px={{ base: 6, lg: 9 }}
                        py={{ base: 4, lg: 5 }}
                        borderRadius={{ base: "md", lg: "lg" }}
                        borderColor={"black"}
                        fontSize={{ base: "18px", lg: "22px" }}
                        height={"inherit"}
                      />

                      <FormErrorMessage fontSize={{ base: "sm", lg: "md" }}>
                        {fieldState.error?.message}
                      </FormErrorMessage>

                      <Text
                        mt="1"
                        fontSize={{ base: "sm", lg: "md" }}
                        color="gray.700"
                      >
                        Order number can be found in the order confirmation
                        email.
                      </Text>
                    </FormControl>
                  )}
                />
              </VStack>

              <Flex mt={8}>
                <Button
                  w="100%"
                  height={"inherit"}
                  borderRadius={{ base: "md", lg: "lg" }}
                  fontSize={{ base: "18px", lg: "22px" }}
                  py={{ base: 4, lg: 5 }}
                  type="submit"
                  disabled={isPendingAuthenticate || isPendingLookup}
                >
                  View order details
                </Button>
              </Flex>
            </form>
          </Box>
          <Box mt={"auto"} fontSize={"22px"}>
            <PoweredByStordFooter divider={false} alignment="center" />
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}
