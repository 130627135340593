import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type {
  Claim,
  CreateShipmentClaimBody,
  OrderDetailsShipmentData,
  PresignedDocumentUpload,
  ShipmentTrackingData,
  ViewOrderDetailsFields,
} from "./types";
import { useApi } from "../../ApiProvider";

export const useShipmentTracking = (
  orderId: string | undefined,
  shipmentId: string | undefined,
  opts: { enabled?: boolean },
) => {
  const api = useApi();

  return useQuery({
    queryKey: ["cx-shipment-tracking", orderId, shipmentId],
    queryFn: () =>
      api.get<{ data: ShipmentTrackingData }>({
        url: `/v1/consumer_experience/orders/${orderId}/shipments/${shipmentId}`,
      }),
    select: ({ data }) => data,
    ...opts,
  });
};

export const useOrderDetails = (
  orderId: string | undefined,
  opts: Omit<
    UseQueryOptions<
      { data: OrderDetailsShipmentData[] },
      {
        status: number;
        data: { errors: { message: string; title: string }[] };
      },
      OrderDetailsShipmentData[]
    >,
    "queryKey" | "queryFn"
  > = {},
) => {
  const api = useApi();

  return useQuery({
    queryKey: ["cx-order-details", orderId],
    queryFn: () =>
      api.get<{ data: OrderDetailsShipmentData[] }>({
        url: `/v1/consumer_experience/orders/${orderId}/shipments`,
      }),
    select: ({ data }) => data,
    ...opts,
  });
};

export const useAuthenticate = () => {
  const api = useApi();

  return useMutation({
    mutationFn: ({
      order_id,
      ...data
    }: ViewOrderDetailsFields & { order_id: string }) =>
      api.post<void>({
        url: `/v1/consumer_experience/orders/${order_id}/authenticate`,
        body: { data },
      }),
  });
};

export const useGlobalOrderLookup = () => {
  const api = useApi();

  return useMutation({
    mutationFn: ({ email, order_number }: ViewOrderDetailsFields) =>
      api.post<{ order_id: string }>({
        url: `/v1/cache/orders`,
        body: { email, order_number },
      }),
  });
};

export const useNetworkDetails = (networkId: string) => {
  const api = useApi();

  return useQuery({
    queryKey: ["cx-network-details", networkId],
    queryFn: () =>
      api.get<{ logo: string; name: string; has_cx_access: boolean }>({
        url: `/v1/cache/brand?network_id=${networkId}`,
      }),
    select: (data) => data,
  });
};

export const useNetworkOrderLookup = () => {
  const api = useApi();

  return useMutation({
    mutationFn: ({
      email,
      network_id,
      order_number,
    }: ViewOrderDetailsFields & { network_id: string }) =>
      api.post<{ order_id: string }>({
        url: `/v1/cache/orders`,
        body: { email, network_id, order_number },
      }),
  });
};

export const useClaimDocumentUpload = () => {
  const api = useApi();

  return useMutation({
    mutationFn: ({
      orderId,
      documents,
    }: {
      orderId: string;
      documents: Array<{ file_name: string; mime_type: string }>;
    }) =>
      api.post<{ data: PresignedDocumentUpload[] }>({
        url: `/v1/consumer_experience/orders/${orderId}/document_upload`,
        body: {
          documents,
        },
      }),
  });
};

export const useCreateShipmentClaim = () => {
  const queryClient = useQueryClient();
  const api = useApi();

  return useMutation({
    mutationFn: ({
      orderId,
      data,
    }: {
      orderId: string;
      data: CreateShipmentClaimBody;
    }) =>
      api.post<{ data: Claim }>({
        url: `/v1/consumer_experience/orders/${orderId}/claims`,
        body: { data },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["cx-shipment-tracking"],
      });
      queryClient.invalidateQueries({
        queryKey: ["cx-order-details"],
      });
    },
  });
};
